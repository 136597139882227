<script>
  import IconButton from "@smui/icon-button";
  import List, { Graphic, Item, Text } from "@smui/list";
  import Menu from "@smui/menu";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import { format as formatDate, isToday } from "date-fns";
  import { compareAsc } from "date-fns/compareAsc";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext, onDestroy, onMount, tick } from "svelte";
  import { flip } from "svelte/animate";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpDeliveryList from "~/components/help/HelpDeliveryList.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import additionalDataForDeliverylist from "~/libs/additionalDataForDeliverylist";
  import addressUtils from "~/libs/addressUtils";
  import arrayUtils from "~/libs/arrayUtils";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    AddressTypeForMap,
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    NotificationCategory,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import desiredDateTime from "~/libs/desiredDateTime";
  import geolocator from "~/libs/geolocator";
  import iosNativeApp from "~/libs/iosNativeApp";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import {
    currentPositionStore,
    needToGettingKnowledge,
    needToReload,
  } from "~/libs/stores";
  import { syncOperationStateOfDriver } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";
  import { getCurrentDateTimeOnJst } from "~/libs/utils";
  import EmptyListContent from "~/pages/List/EmptyListContent.svelte";
  import EmptyTabContent from "~/pages/List/EmptyTabContent.svelte";
  import ListItem from "~/pages/List/ListItem.svelte";
  import RouteMap from "~/pages/List/RouteMap.svelte";
  import SyncFailureListDialog from "~/pages/List/SyncFailureListDialog.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 配達リスト @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
  let shippingList = userContext.deliveryList ?? [];

  /** 配送ステータス（未・済・不）ごとに分割した配達リスト */
  const deliveryListByStatus = {
    /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} 未配達の荷物リスト */
    未: [],
    /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} 配達済の荷物リスト */
    済: [],
    /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} 配達不可の荷物リスト */
    不: [],
  };

  /** ページの初期化が完了したか否かのフラグ */
  let pageInitialized = false;

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;
  /** @type {import("svelte").ComponentType<HelpDeliveryList>} */
  let helpContents;

  /** @type {import("svelte/store").Unsubscriber} */
  let currentPositionStoreUnsubscriber;

  /** ヘッダの地図アイコン押下時に表示されるMenu @type {Menu} */
  let headerMapMenu;
  /** 最後に取得した位置情報 @type {GeolocationPosition} */
  let lastPosition;

  /**
   * ダイアログコンポーネント（経路地図表示用）のインスタンス
   * @type {ConfirmDialog}
   */
  let routeMapDialog;

  /**
   * ダイアログコンポーネント（経路案内メッセージ表示用）のインスタンス
   * @type {ConfirmDialog}
   */
  let routeMapMessageDialog;

  /**
   * ダイアログコンポーネント（同期失敗荷物リスト表示用）のインスタンス
   * @type {SyncFailureListDialog}
   */
  let syncFailureListDialog;

  /** 配達リスト区分タブ定義 */
  const deliveryListTabs = [
    {
      icon: "pending",
      label: "未配達",
      length: 0,
    },
    {
      icon: "check_circle",
      label: "配達完了",
      length: 0,
    },
    {
      icon: "error_outline",
      label: "配達不可",
      length: 0,
    },
  ];
  /** アクティブな配達リスト区分タブ */
  let activeTab = deliveryListTabs[0];

  /** D&Dによる並替中の荷物のリスト番号 @type {number} */
  let draggingListItemIndex;

  /** 指定日時超過の荷物の個数 @type {number} */
  let numberOfOverduePackages = 0;

  /** 本日指定の荷物の個数 @type {number} */
  let numberOfTodayPackages = 0;

  /** 未配達リスト内の引継ぎ待ちの荷物の個数 @type {number} */
  let numberOfWaitingHandoverPackagesInUndeliveredList = 0;

  /** 配達不可リスト内の引継ぎ待ちの荷物の個数 @type {number} */
  let numberOfWaitingHandoverPackagesInUndeliverableList = 0;

  /**
   * 指定時間内（超過も含む）の荷物についての、終了時刻毎の個数のリスト
   * @type {Array<{endTime: string, num: number}>}
   */
  let packageListInDesiredTime;

  /** プッシュ通知による更新情報がある場合にtrue @type {boolean} */
  let hasUpdates = false;

  /** プッシュ通知により更新された荷物件数の文字列 @type {string} */
  let updatesCountString = "";

  /** 送信失敗リストにひとつでも荷物がある場合にtrue @type {boolean} */
  let hasSyncFailurePackages = userContext.syncFailureList?.length > 0;

  // 配達リストが更新された場合に実行
  $: onUpdateList(shippingList);
  $: checkDeadlineAndDesiredDate(deliveryListByStatus.未);

  // ページの初期化処理
  loadingProgress
    .wrapAsync(async () => {
      // 現在地の取得
      geolocator.requestCurrentPosition(false);
      currentPositionStoreUnsubscriber = currentPositionStore.subscribe(
        (position) => {
          if (position) {
            lastPosition = position;
            deliveryListUtils.setDistance(shippingList, position);
            refreshListItem(); // リスト項目を再描画して距離を反映
          }
        },
      );

      // 配達リストの初期化
      if (await initDeliveryList($needToReload, $needToGettingKnowledge)) {
        needToReload.set(false);
        needToGettingKnowledge.set(false);
        // 配達リストの内容に応じてデフォルトで表示するタブを「未配達」から変更
        const listByStatus = userContext.getNumberOfPackagesGroupByStatus();
        if (listByStatus.未 == 0 && listByStatus.不 > 0) {
          activeTab = deliveryListTabs[2];
        }

        if (!appContext.firstDeliveryListOpened && listByStatus.未 > 0) {
          helpContents = HelpDeliveryList;
          helpBase = HelpBase;
          appContext.firstDeliveryListOpened = true;
          appContext.store();
        }
      } else {
        toast.error($_("errors.getListFailed"));
      }
    })()
    .finally(() => {
      pageInitialized = true;
    });

  onMount(() => {
    // 60秒ごとに現在地を取得
    const interval = setInterval(() => {
      // 未配達の荷物があるか、配達不可の荷物があって且つ現在のタブが配達不可の場合は現在地を取得
      if (
        deliveryListByStatus.未.length > 0 ||
        (deliveryListByStatus.不.length > 0 && activeTab == deliveryListTabs[2])
      ) {
        geolocator.requestCurrentPosition(
          document.visibilityState === "visible",
        );
      }
    }, 60 * 1000);

    // 前回リマインドから30分以上経っていたら、指定時間ありの荷物のリマインドを行う
    const now = new Date();
    const thirtyMinutesAgo = new Date();
    thirtyMinutesAgo.setMinutes(now.getMinutes() - 30);

    /** @type {Date} */
    let lastTimeOfRemind = userContext.timeOfShowingDeliveryRemind
      ? new Date(userContext.timeOfShowingDeliveryRemind)
      : undefined;

    if (
      !lastTimeOfRemind ||
      lastTimeOfRemind.getTime() < thirtyMinutesAgo.getTime()
    ) {
      packageListInDesiredTime = getPackageListInDesiredTime();

      if (packageListInDesiredTime) {
        let message = "<b>指定時間内の荷物</b><div style='margin-top: 5px'>";
        for (let i = 0; i < packageListInDesiredTime.length; i++) {
          message += "<div>";

          if (Number(packageListInDesiredTime[i].endTime) <= now.getHours()) {
            message += "<span style='color: #ffff00;'>";
          }

          message +=
            "<span style='display: inline-block; width: 3.5em; text-align: right;'>";
          if (packageListInDesiredTime[i].endTime !== "24") {
            message += "～" + packageListInDesiredTime[i].endTime + "時";
          } else {
            message += "本日中";
          }
          message +=
            "</span>：<span style='display: inline-block; width: 2.5em; text-align: right;'>" +
            packageListInDesiredTime[i].num +
            "個</span>";

          if (Number(packageListInDesiredTime[i].endTime) <= now.getHours()) {
            message +=
              "<span class='material-icons' style='font-size: 18px; margin-left: 5px; position: relative; top: 3px;'>warning</span></span>";
          }
          message += "</div>";
        }
        message += "</div>";
        toast.remindDesiredTime(message, {
          duration: 10000,
          popsWhenPageMoved: true,
        });
      }

      userContext.timeOfShowingDeliveryRemind = now;
      userContext.store();
    }

    return () => clearInterval(interval);
  });

  onDestroy(() => {
    currentPositionStoreUnsubscriber?.();
  });

  /**
   * 配達リストを再読込する。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} newShippingList
   */
  export async function reloadShippingList(newShippingList) {
    shippingList = newShippingList;
    refreshListItem();
  }

  /**
   * 配達リストが更新された場合に実行するリアクティブ処理。
   *
   * Svelte Reactive Statementのバグにより、setDistance呼出時などで不必要に本関数が呼び出されるため、
   * 極力時間のかかる処理を実行しないように最適化を行うこと。
   * @param {unknown} dummy
   */
  function onUpdateList(/* eslint no-unused-vars: 0 */ dummy) {
    // 配達リストから未・済・不のサブリストを作成し、変更されていた場合は反映
    (() => {
      const r = shippingList.reduce(
        (accumulator, currentValue) => {
          accumulator[currentValue.statusText].push(currentValue);
          return accumulator;
        },
        {
          未: [],
          済: [],
          不: [],
        },
      );
      if (!arrayUtils.equals(deliveryListByStatus.未, r.未)) {
        deliveryListTabs[0].length = (deliveryListByStatus.未 = r.未).length;
      }
      if (!arrayUtils.equals(deliveryListByStatus.済, r.済)) {
        deliveryListTabs[1].length = (deliveryListByStatus.済 = r.済).length;
      }
      if (!arrayUtils.equals(deliveryListByStatus.不, r.不)) {
        deliveryListTabs[2].length = (deliveryListByStatus.不 = r.不).length;
      }
    })();

    // 引継ぎ待ちの荷物の個数を算出
    numberOfWaitingHandoverPackagesInUndeliveredList = 0;
    numberOfWaitingHandoverPackagesInUndeliverableList = 0;
    for (let i = 0; i < deliveryListByStatus.未.length; i++) {
      if (deliveryListByStatus.未[i].isRegisteredHandover) {
        numberOfWaitingHandoverPackagesInUndeliveredList++;
      }
    }
    for (let i = 0; i < deliveryListByStatus.不.length; i++) {
      if (deliveryListByStatus.不[i].isRegisteredHandover) {
        numberOfWaitingHandoverPackagesInUndeliverableList++;
      }
    }

    // ネイティブアプリに件数情報を通知
    syncDeliveryListToWebKit();

    const updateCount = {
      未: 0,
      済: 0,
      不: 0,
    };
    for (const item of shippingList) {
      if (Number.isInteger(item.receivedPushType)) {
        // プッシュ通知による更新がある場合
        updateCount[item.statusText]++;
        hasUpdates = true;
      }
    }

    if (hasUpdates) {
      updatesCountString = "荷物の情報に更新があります(";
      if (updateCount.未 > 0) {
        updatesCountString += `未${updateCount.未}、`;
      }
      if (updateCount.済 > 0) {
        updatesCountString += `完了${updateCount.済}、`;
      }
      if (updateCount.不 > 0) {
        updatesCountString += `不可${updateCount.不}、`;
      }
      updatesCountString = updatesCountString.slice(0, -1) + ")";
    }
  }

  /**
   * 配達リストの初期化（構築・再読込）を行う
   * @param {boolean} [syncWithBackend=false] 現在のリストをバックエンドから取得したリストと同期する場合にtrue
   * @param {boolean} [gettingKnowledge=false] 配達ナレッジをバックエンドから取得する場合にtrue
   * @param {string} [triggeredBy] 配達リストの再読込がトリガーされた理由 (デバッグ用変数) リフレッシュ or コンテキストマイグレーション
   * @returns {Promise<boolean>} 処理成功時にtrue
   */
  async function initDeliveryList(
    syncWithBackend = false,
    gettingKnowledge = false,
    triggeredBy = "contextMigration",
  ) {
    // デバッグのためBE同期を伴う再読込時にログを出力
    if (syncWithBackend) {
      logger.info("[List] 配達リストの再読込を開始します", {
        username: userContext.loginUser?.username,
        hasDeliveryList: userContext.deliveryList ? true : false,
        syncWithBackend: syncWithBackend,
        triggeredBy: triggeredBy,
      });
    }

    try {
      if (userContext.deliveryList) {
        /* 配達リストがローカルストレージに存在する場合 */

        // 配完後一定時間が経過した荷物は配達リストから削除
        const prunedDeliveryList = deliveryListUtils.pruneOldDeliveredPackages(
          userContext.deliveryList,
        );
        if (!arrayUtils.equals(userContext.deliveryList, prunedDeliveryList)) {
          shippingList = prunedDeliveryList;
        }

        if (!syncWithBackend) {
          // ネイティブアプリに件数情報を通知
          syncDeliveryListToWebKit();

          if (gettingKnowledge) {
            // 配達ナレッジを非同期で取得
            getShippingKnowledge();
          }

          // バックエンドと同期しない場合はここで終了
          return true;
        }
      }

      // バックエンドの配達リストと同期
      await syncDeliveryListWithBackend();

      return true;
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
        logout();
        return true;
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
        logout();
        return true;
      } else {
        // オフライン状態やサーバーエラー応答等が発生した場合
        logger.error(
          "[List] 配達リストの初期化（構築・再読込）でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
            syncWithBackend: syncWithBackend,
          },
          error,
        );
        // ロールに応じて閉塞フラグを確認し、オフラインモード切替えが可能かを判定
        if (
          (userContext.hasContractDriverRole &&
            import.meta.env.VITE_DISABLED_OFFLINE_MODE_PICKUP_AND_SORT !==
              "true") ||
          (userContext.hasDriverRole &&
            import.meta.env.VITE_DISABLED_OFFLINE_MODE_DELIVERED !== "true")
        ) {
          // オフラインモード切替えが可能な場合、オフラインモード切替えヘルプを表示
          if (error instanceof OfflineException) {
            toast.recommendOfflineMode($_("errors.offline"));
          } else {
            toast.recommendOfflineMode($_("errors.defaultMessage"));
          }
          return true;
        } else {
          // オフラインモード切替えが不可の場合、取得失敗のエラーメッセージを表示
          return false;
        }
      }
    }
  }

  /**
   * 配達リストをバックエンドと同期する
   */
  async function syncDeliveryListWithBackend() {
    // -------------------------------------------------------------------------
    // Step 1: バックエンドから最新の配達リストを取得
    // -------------------------------------------------------------------------

    /** バックエンド上の最新の配達リスト */
    const latestDeliveryList =
      /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */ (
        await backendApi.getDeliveryList()
      );

    // -------------------------------------------------------------------------
    // Step 2: ローカルの配達リストをバックエンドから取得した最新の配達リストで更新
    // -------------------------------------------------------------------------

    /** 非同期初期化処理のPromiseリスト @type {Array<Promise<*>>} */
    const initPromiseArray = [];

    /** 新しい配達リスト @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
    const newDeliveryList = [];

    /** ローカルにのみ存在する荷物の一覧 @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
    const packagesExistOnlyInLocal = [];

    /** 返品対象となった荷物の一覧 @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
    const packagesToBeReturned = [];

    if (shippingList.length > 0) {
      // ローカルの配達リストが存在する場合
      for (const item of shippingList) {
        if (item.statusText === "済") {
          newDeliveryList.push(item);
          continue;
        }

        const found = deliveryListUtils.findByTrackingNumber(
          latestDeliveryList,
          item.trackingNumber,
        );
        if (found) {
          /* バックエンドに該当の配送情報が存在する場合 */
          // TODO: BE側での更新項目があり・かつPush通知で表示していなければ通知する処理を入れる
          if (
            item.statusText === "未" &&
            Number.isInteger(found.returnStatus)
          ) {
            // 未配達の荷物で返品対象となった場合はリストに追加
            packagesToBeReturned.push(found);
          }

          initPromiseArray.push(
            deliveryListUtils.initDeliveryPackage(found, item),
          );
          newDeliveryList.push(found);
        } else {
          /* バックエンド上に該当の配送情報が存在しない場合 */
          packagesExistOnlyInLocal.push(item);
        }
      }
    } else {
      // ローカルの配達リストが存在しない場合
      for (const item of latestDeliveryList) {
        if (Number.isInteger(item.returnStatus)) {
          // 返品対象となっている場合はリストに追加
          packagesToBeReturned.push(item);
        }
      }
    }

    // -------------------------------------------------------------------------
    // Step 3: バックエンド上にのみ存在する荷物の初期化
    // -------------------------------------------------------------------------

    /** 配達登録時にBEとの同期に失敗した荷物のリスト @type {Array<import("~/libs/commonTypes").SyncFailureDeliveryPackage>} */
    const syncFailureList = userContext.syncFailureList ?? [];

    /** バックエンド上にのみ存在する荷物の一覧 */
    const packagesExistOnlyInBE = latestDeliveryList
      .filter(
        (item) =>
          !newDeliveryList.find(
            (newItem) => newItem.trackingNumber === item.trackingNumber,
          ),
      )
      .filter(
        (item) =>
          !syncFailureList.find(
            (syncFailureItem) =>
              syncFailureItem.trackingNumber === item.trackingNumber,
          ),
      );

    // DeliveryPackage用の初期化
    packagesExistOnlyInBE.forEach((item) => {
      initPromiseArray.push(deliveryListUtils.initDeliveryPackage(item));
    });

    // 持ち出し日時の昇順でソートして新規配達リストに統合（localStorageに配達リストがない場合は全量ソート）
    deliveryListUtils.sortWithAscOrder(packagesExistOnlyInBE);
    newDeliveryList.push(...packagesExistOnlyInBE);

    // 配達リスト番号の採番
    deliveryListUtils.numberingAllPackages(newDeliveryList, syncFailureList);

    // -------------------------------------------------------------------------
    // Step 4: 非同期初期化処理の合流
    // -------------------------------------------------------------------------

    await Promise.all(initPromiseArray);

    // -------------------------------------------------------------------------
    // Step 5: 配達リストの各お届け先への距離を反映
    // -------------------------------------------------------------------------

    if (newDeliveryList.length > 0) {
      deliveryListUtils.setDistance(newDeliveryList, $currentPositionStore);
    }

    // -------------------------------------------------------------------------
    // Step 6: Listページの配達リストに反映
    // -------------------------------------------------------------------------

    let isChanged = false;

    // localStorageに配達リストがあり、BEからの同期によってリストに追加または削除された荷物がある場合はDatadogにログを出力、かつ配送実績をBEに同期
    // 次の処理でlocalStorageの配達リストが更新されるため、このタイミングで処理を実施
    if (
      (userContext.deliveryList &&
        (packagesExistOnlyInBE.length > 0 ||
          packagesExistOnlyInLocal.length > 0)) ||
      packagesToBeReturned.length > 0
    ) {
      isChanged = true;

      const local = shippingList.map((e) => e.trackingNumber);
      const synced = latestDeliveryList.map((e) => e.trackingNumber);
      logger.error(
        "[List] 配達リスト同期時、追加または削除された荷物がありました",
        {
          username: userContext.loginUser?.username,
          localDeliveryListTrackingNumbers: local,
          syncedDeliveryListTrackingNumbers: synced,
        },
      );
    }

    // 配達リストを反映
    shippingList = newDeliveryList;

    // -------------------------------------------------------------------------
    // Step 7: 追加・変更内容の通知
    // -------------------------------------------------------------------------

    if (userContext.deliveryList) {
      // localStorageに配達リストが格納されていた場合は追加・変更内容を通知
      if (packagesExistOnlyInBE.length > 0) {
        // 手元の配達リストに追加した荷物がある場合は通知を表示
        toast.info(
          $_("errors.deliveryPackageThatAddedFromBackend", {
            values: {
              trackingNumbers: packagesExistOnlyInBE
                .map((e) => formatTrackingNumber(e.trackingNumber))
                .join("、"),
            },
          }),
        );
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.INFO,
          $_("errors.deliveryPackageThatAddedFromBackend", {
            values: {
              trackingNumbers: packagesExistOnlyInBE
                .map((e) => formatTrackingNumber(e.trackingNumber))
                .join("、"),
            },
          }),
        );
      }
      if (packagesExistOnlyInLocal.length > 0) {
        // 手元の配達リストから削除した荷物がある場合は通知を表示
        toast.info(
          $_("errors.deliveryPackageThatRemovedFromBackend", {
            values: {
              trackingNumbers: packagesExistOnlyInLocal
                .map((e) => formatTrackingNumber(e.trackingNumber))
                .join("、"),
            },
          }),
        );
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.INFO,
          $_("errors.deliveryPackageThatRemovedFromBackend", {
            values: {
              trackingNumbers: packagesExistOnlyInLocal
                .map((e) => formatTrackingNumber(e.trackingNumber))
                .join("、"),
            },
          }),
        );
      }
    }
    if (packagesToBeReturned.length > 0) {
      // 返品対象となった荷物がある場合は通知を表示
      toast.info(
        $_("errors.deliveryPackageThatMovedFromBackend", {
          values: {
            trackingNumbers: packagesToBeReturned
              .map((e) => formatTrackingNumber(e.trackingNumber))
              .join("、"),
          },
        }),
      );
      notificationHistoryUtils.deleteAndAddHistory(
        userContext.loginUser.username,
        NotificationCategory.INFO,
        $_("errors.deliveryPackageThatMovedFromBackend", {
          values: {
            trackingNumbers: packagesToBeReturned
              .map((e) => formatTrackingNumber(e.trackingNumber))
              .join("、"),
          },
        }),
      );
    }

    // -------------------------------------------------------------------------
    // Step 8: localStorageの配達リストに反映
    // -------------------------------------------------------------------------

    userContext.deliveryList = shippingList;
    userContext.store();

    if (isChanged) {
      // 配達リストに変更があれば配送実績をBEに同期
      const updatedAt = formatDate(
        getCurrentDateTimeOnJst(),
        "yyyy-MM-dd HH:mm:ss",
      );
      syncOperationStateOfDriver(userContext, updatedAt);
    }

    // 手元の配達リストから削除した荷物の付加データも削除
    for (const item of packagesExistOnlyInLocal) {
      additionalDataForDeliverylist.deleteByTrackingNumber(item.trackingNumber);
    }

    // -------------------------------------------------------------------------
    // Step 9: 非同期で配達ナレッジを収集する
    // -------------------------------------------------------------------------

    getShippingKnowledge();
  }

  /**
   * アプリがWkWebView上で動作している場合は、WebKitに配達リストの件数情報を送信する。
   */
  function syncDeliveryListToWebKit() {
    const messageHandlers = iosNativeApp.messageHandlers;
    if (messageHandlers) {
      const countObject = {
        undelivered: deliveryListByStatus.未.length,
        undeliverable: deliveryListByStatus.不.length,
        delivered: deliveryListByStatus.済.length,
      };
      messageHandlers.callbackHandler.postMessage(JSON.stringify(countObject));
    }
  }

  /**
   * 非同期で1秒毎に10件の配達ナレッジを取得する
   */
  async function getShippingKnowledge() {
    const batchSize = 10;
    const delay = 1000;
    const totalBatches = Math.ceil(shippingList.length / batchSize);

    for (let i = 0; i < totalBatches; i++) {
      const start = i * batchSize;
      const end = start + batchSize;
      const batch = shippingList.slice(start, end);

      if (await execSearchShippingKnowledgeAPI(batch)) {
        // 配達ナレッジが取得できた場合は、配達リストを更新
        refreshListItem();
        userContext.store();
      }

      if (i < totalBatches - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }

  /**
   * 配達ナレッジを取得するAPIを呼び出す
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} batch
   * @returns {Promise<boolean>} 配達ナレッジが取得できた場合にtrue
   */
  async function execSearchShippingKnowledgeAPI(batch) {
    /** @type {import("~/libs/backendApi").SearchKnowledgeRequests} */
    const requestBody = [];
    for (const deliveryPackage of batch) {
      requestBody.push({
        trackingNumber: deliveryPackage.trackingNumber,
        address: {
          [AddressTypeForMap.REGISTERED]: addressUtils.joinWithSpace(
            deliveryPackage.receiverAddress1,
            deliveryPackage.receiverAddress2,
          ),
          [AddressTypeForMap.INPUTTED]: deliveryPackage.enteredAddress,
          [AddressTypeForMap.CORRECTED]:
            deliveryPackage.correctedReceiverAddress,
        }[deliveryPackage.addressForMap],
        radius: 50,
        latitude: deliveryPackage.latlon?.latitude,
        longitude: deliveryPackage.latlon?.longitude,
      });
    }

    try {
      const shippingKnowledgeResponse =
        await backendApi.searchShippingNnowledge(requestBody);

      let hasShippingKnowledge = false;
      for (const shippingKnowledge of shippingKnowledgeResponse) {
        if (
          !shippingKnowledge.byAddress &&
          shippingKnowledge.neighborhoods.length == 0
        ) {
          // 宅配ナレッジが登録されていなくても送り状番号だけは返ってくるため、ナレッジがない場合はスキップ
          continue;
        }

        const targetPackage = batch.find(
          (e) => e.trackingNumber === shippingKnowledge.trackingNumber,
        );
        if (targetPackage) {
          targetPackage.shippingKnowledge = {
            byAddress: shippingKnowledge.byAddress,
            neighborhoods: shippingKnowledge.neighborhoods,
          };
          hasShippingKnowledge = true;
        }
      }

      if (hasShippingKnowledge) {
        return true;
      }
    } catch (error) {
      // 宅配ナレッジがなくても業務は継続できるため、エラーログだけ出して続行
      logger.error(
        "[deliveryListUtils] 宅配ナレッジの取得・配達リストへのマージでエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          batch: batch ? batch.map((e) => e.trackingNumber) : null,
        },
        error,
      );
    }
    return false;
  }

  function logout() {
    pageRouter.moveToLogin();
  }

  async function onClickRefreshDeliveryList() {
    if (!(await initDeliveryList(true, true, "refreshButton"))) {
      toast.error($_("errors.getListFailed"));
    }
  }

  function clickConfirm() {
    helpBase = null;
    helpContents = null;
  }

  /**
   * ドラッグ＆ドロップによる荷物の入替を開始する際に呼び出されるdragstartイベントのコールバック。
   * @param {DragEvent} event
   * @param {number} listItemIndex
   */
  function handleDragStart(event, listItemIndex) {
    event.dataTransfer.setData("text/plain", String(listItemIndex)); // データなしだとスマホでD&Dできないためダミーデータを設定
    event.dataTransfer.effectAllowed = "move";
    draggingListItemIndex = listItemIndex;
  }

  /**
   * ドラッグ＆ドロップによる荷物の入替中に呼び出されるdragoverイベントのコールバック。
   * @param {DragEvent} event
   * @param {number} droppingListItemIndex
   */
  function handleDragOver(event, droppingListItemIndex) {
    event.dataTransfer.dropEffect = "move";

    const targetElement = /** @type {HTMLElement} */ (event.currentTarget);
    targetElement.style.backgroundColor = "#eee";
    if (droppingListItemIndex < draggingListItemIndex) {
      targetElement.style.borderTop = "3px solid #ffa500";
    } else if (droppingListItemIndex > draggingListItemIndex) {
      targetElement.style.borderBottom = "3px solid #ffa500";
    }
  }

  /**
   * ドラッグ＆ドロップによる荷物の入替中に呼び出されるdragleaveイベントのコールバック。
   * @param {DragEvent} event
   */
  function handleDragLeave(event) {
    const targetElement = /** @type {HTMLElement} */ (event.currentTarget);
    targetElement.style.backgroundColor = null;
    targetElement.style.borderTop = null;
    targetElement.style.borderBottom = null;
  }

  /**
   * ドラッグ＆ドロップによる荷物の入替を完了する際に呼び出されるdropイベントのコールバック。
   * @param {DragEvent} event
   * @param {number} droppingListItemIndex
   */
  function handleDrop(event, droppingListItemIndex) {
    event.dataTransfer.dropEffect = "move";

    const targetElement = /** @type {HTMLElement} */ (event.currentTarget);
    targetElement.style.backgroundColor = null;
    targetElement.style.borderTop = null;
    targetElement.style.borderBottom = null;

    // リストの順番を入れ替え
    const dropItem = shippingList.splice(draggingListItemIndex, 1)[0];
    shippingList.splice(droppingListItemIndex, 0, dropItem);
    draggingListItemIndex = undefined;

    // 手動で並び替えたリストを画面に反映
    shippingList = shippingList;
    userContext.store();
  }

  /**
   * 本日指定と指定日時超過の荷物の数をカウントする
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} shippingList
   */
  function checkDeadlineAndDesiredDate(shippingList) {
    /** @type {Date} 現在の日時 */
    const now = new Date();
    /** @type {Date} 現在の日付に加え、時間をデフォルトの9時に設定したもの */
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      9,
      0,
      0,
      0,
    );
    /** @type {number} 指定日時超過の荷物の数 */
    numberOfOverduePackages = 0;
    /** @type {number} 本日指定の荷物の数 */
    numberOfTodayPackages = 0;

    for (let i = 0; i < shippingList.length; i++) {
      /** @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>} 指定された(再)配達日時 */
      const calculatedDateAndTime = desiredDateTime.resolve(shippingList[i]);

      // (再)配達日時が本日指定または指定日時超過に含まれるかどうかを確認する
      if (calculatedDateAndTime.length === 1) {
        // (再)配達日時が1つ指定されている場合

        // 指定の日付と現在の日付を比較
        // 日付だけを比較するため、時間をデフォルトの9時に設定
        const date = new Date(calculatedDateAndTime[0].date);
        date.setHours(9, 0, 0, 0);
        const compareAscResult = compareAsc(today, date);

        if (compareAscResult === 0) {
          // 日付が等しい場合は指定時間帯の終了時刻で判定
          // 指定時間帯の終了時刻を取得
          let endHour = null;
          if (calculatedDateAndTime[0].timeFrame) {
            if (calculatedDateAndTime[0].timeFrame.substring(2, 4) === "00") {
              endHour = parseInt("24");
            } else {
              endHour = parseInt(
                calculatedDateAndTime[0].timeFrame.substring(2, 4),
              );
            }
          } else {
            // 希望時間なし（= 本日中）の場合
            // 0時～24時として扱いたいので「24」を設定
            endHour = parseInt("24");
          }

          // 指定時間帯の終了時刻と現在時刻を比較
          if (now.getHours() >= endHour) {
            numberOfOverduePackages++;
          } else {
            numberOfTodayPackages++;
          }
        } else if (compareAscResult === 1) {
          // 日付が異なる場合は指定日時超過としてカウント
          numberOfOverduePackages++;
        }
      } else {
        // (再)配達日時が指定されていない場合 もしくは 複数の希望時間帯が指定されている場合は、スキップ
        continue;
      }
    }
  }

  /**
   * リスト項目を再描画する。（shippingListの構造変更がない場合に使用）
   */
  function refreshListItem() {
    deliveryListByStatus.未 = deliveryListByStatus.未;
    deliveryListByStatus.済 = deliveryListByStatus.済;
    deliveryListByStatus.不 = deliveryListByStatus.不;
  }

  /**
   * 指定時間内（超過も含む）の荷物について、終了時刻毎の個数のリストを取得
   * @returns {Array<{endTime: string, num: number}>}
   */
  function getPackageListInDesiredTime() {
    /** @type {Array<{endTime: string, num: number}>} */
    let packageListInDesiredTime;
    const now = new Date();

    for (let i = 0; i < deliveryListByStatus.未.length; i++) {
      /** @type {string} */
      let desiredTime = "";

      if (
        deliveryListByStatus.未[i].specifiedPickupDatetime
          ?.desiredRedeliveryDatetime
      ) {
        // 再配達の希望日時が１つ指定されている場合
        if (
          isToday(
            deliveryListByStatus.未[i].specifiedPickupDatetime
              .desiredRedeliveryDatetime.date,
          )
        ) {
          // 希望日が本日の場合
          if (
            deliveryListByStatus.未[i].specifiedPickupDatetime
              .desiredRedeliveryDatetime.timeFrame
          ) {
            // 時間帯が指定されている場合
            desiredTime =
              deliveryListByStatus.未[i].specifiedPickupDatetime
                .desiredRedeliveryDatetime.timeFrame;
          } else {
            // 希望時間なし（= 本日中）の場合
            // 0時～24時として扱いたいので「0024」を設定
            desiredTime = "0024";
          }
        } else {
          // 希望日が本日以外の場合は対象外
          continue;
        }
      } else if (
        deliveryListByStatus.未[i].specifiedPickupDatetime
          ?.availablePickupDatetime
      ) {
        // 再配達の受取可能時間帯が設定されている場合
        for (
          let j = 0;
          j <
          deliveryListByStatus.未[i].specifiedPickupDatetime
            .availablePickupDatetime.length;
          j++
        ) {
          if (
            isToday(
              deliveryListByStatus.未[i].specifiedPickupDatetime
                .availablePickupDatetime[j].date,
            )
          ) {
            // 受取可能時間帯の設定に本日がある場合
            if (
              deliveryListByStatus.未[i].specifiedPickupDatetime
                .availablePickupDatetime[j].timeFrame === "0000"
            ) {
              // 受取不可の場合は対象外
              break;
            } else {
              // 本日受取可能な時間帯がある場合
              // 「XX時以降」は「XX00」の形で入ってくるが、表示前に時間帯でソートを行いたい関係で「00」を「24」に置き換える
              desiredTime =
                deliveryListByStatus.未[
                  i
                ].specifiedPickupDatetime.availablePickupDatetime[
                  j
                ].timeFrame.substring(0, 2) + "24";
              break;
            }
          }
        }
        if (desiredTime === "") {
          // desiredTimeが未設定の場合（= 再配達の設定に本日があるが受取不可の場合、または再配達の設定に本日がない場合）
          continue;
        }
      } else if (
        deliveryListByStatus.未[i].redeliveryContext?.adjustedRedeliveryDatetime
      ) {
        // 電話での調整日時がある場合
        if (
          isToday(
            deliveryListByStatus.未[i].redeliveryContext
              ?.adjustedRedeliveryDatetime.date,
          )
        ) {
          // 希望日が本日の場合
          if (
            deliveryListByStatus.未[i].redeliveryContext
              ?.adjustedRedeliveryDatetime.timeFrame
          ) {
            // 時間帯が指定されている場合
            desiredTime =
              deliveryListByStatus.未[i].redeliveryContext
                ?.adjustedRedeliveryDatetime.timeFrame;
          } else {
            // 希望時間なし（= 本日中）の場合
            // 0時～24時として扱いたいので「0024」を設定
            desiredTime = "0024";
          }
        } else {
          // 希望日が本日以外の場合は対象外
          continue;
        }
      } else if (
        deliveryListByStatus.未[i].desiredDate ||
        deliveryListByStatus.未[i].desiredTime
      ) {
        // 配達希望日（出荷時）が登録されている場合
        if (!deliveryListByStatus.未[i].desiredDate) {
          // 希望日なし、希望時間ありの場合
          desiredTime = deliveryListByStatus.未[i].desiredTime;
        } else {
          // 希望日ありの場合
          if (isToday(deliveryListByStatus.未[i].desiredDate)) {
            // 希望日が本日の場合
            if (deliveryListByStatus.未[i].desiredTime) {
              // 希望時間ありの場合
              desiredTime = deliveryListByStatus.未[i].desiredTime;
            } else {
              // 希望時間なし（= 本日中）の場合
              // 0時～24時として扱いたいので「0024」を設定
              desiredTime = "0024";
            }
          } else {
            // 希望日が本日以外の場合は対象外
            continue;
          }
        }
      } else {
        // 再配達の希望日の設定がない場合は対象外
        continue;
      }

      if (Number(desiredTime.substring(0, 2)) <= now.getHours()) {
        // 希望時間の開始時刻が現在時刻以前の場合
        let index = 0;
        for (
          ;
          index <
          (packageListInDesiredTime ? packageListInDesiredTime.length : 0);
          index++
        ) {
          if (
            desiredTime.substring(2, 4) ===
            packageListInDesiredTime[index].endTime
          ) {
            // 希望時間のリストに対象の終了時刻の項目が既に存在する場合
            packageListInDesiredTime[index].num++;
            break;
          }
        }
        if (
          index ===
          (packageListInDesiredTime ? packageListInDesiredTime.length : 0)
        ) {
          // 希望時間のリストに対象の終了時刻の項目が存在しなかった場合
          if (packageListInDesiredTime) {
            // 希望時間のリストが存在する場合
            packageListInDesiredTime.push({
              endTime: desiredTime.substring(2, 4),
              num: 1,
            });
          } else {
            // 希望時間のリストが存在しない場合
            packageListInDesiredTime = [
              { endTime: desiredTime.substring(2, 4), num: 1 },
            ];
          }
        }
      }
    }

    // 希望時間のリストを昇順にソート
    if (packageListInDesiredTime) {
      packageListInDesiredTime.sort(
        (a, b) => Number(a.endTime) - Number(b.endTime),
      );
    }

    return packageListInDesiredTime;
  }

  /**
   * 更新がある荷物までスクロールする
   */
  async function scrollToUpdatedPackage() {
    let targetTrackingNumber = "";
    let updatedTrackingNumber = {
      未: "",
      済: "",
      不: "",
    };

    // 未・済・不ごとに、更新があるリスト上最初の荷物のTrackingNumberを取得
    for (const item of deliveryListByStatus.未) {
      if (Number.isInteger(item.receivedPushType)) {
        updatedTrackingNumber.未 = item.trackingNumber;
        break;
      }
    }
    for (const item of deliveryListByStatus.済) {
      if (Number.isInteger(item.receivedPushType)) {
        updatedTrackingNumber.済 = item.trackingNumber;
        break;
      }
    }
    for (const item of deliveryListByStatus.不) {
      if (Number.isInteger(item.receivedPushType)) {
        updatedTrackingNumber.不 = item.trackingNumber;
        break;
      }
    }

    // 表示中タブに更新荷物があったらそこにスクロール
    // 表示中タブない場合は、更新がある別のタブを表示したうえでスクロール
    if (activeTab.label === "未配達") {
      if (updatedTrackingNumber.未) {
        targetTrackingNumber = updatedTrackingNumber.未;
      } else if (updatedTrackingNumber.済) {
        targetTrackingNumber = updatedTrackingNumber.済;
        activeTab = deliveryListTabs[1];
      } else if (updatedTrackingNumber.不) {
        targetTrackingNumber = updatedTrackingNumber.不;
        activeTab = deliveryListTabs[2];
      }
    } else if (activeTab.label === "配達完了") {
      if (updatedTrackingNumber.済) {
        targetTrackingNumber = updatedTrackingNumber.済;
      } else if (updatedTrackingNumber.未) {
        targetTrackingNumber = updatedTrackingNumber.未;
        activeTab = deliveryListTabs[0];
      } else if (updatedTrackingNumber.不) {
        targetTrackingNumber = updatedTrackingNumber.不;
        activeTab = deliveryListTabs[2];
      }
    } else {
      if (updatedTrackingNumber.不) {
        targetTrackingNumber = updatedTrackingNumber.不;
      } else if (updatedTrackingNumber.未) {
        targetTrackingNumber = updatedTrackingNumber.未;
        activeTab = deliveryListTabs[0];
      } else if (updatedTrackingNumber.済) {
        targetTrackingNumber = updatedTrackingNumber.済;
        activeTab = deliveryListTabs[1];
      }
    }
    await tick();
    document
      .getElementById(targetTrackingNumber)
      ?.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * 表示住所切替時に住所ナレッジを取得する
   * @param {CustomEvent<import("~/libs/commonTypes").DeliveryPackage>} event
   */
  async function getShippingKnowledgeForSingle(event) {
    event.detail.shippingKnowledge = null;
    await execSearchShippingKnowledgeAPI([event.detail]);
    // 配達リストを更新
    refreshListItem();
    userContext.store();
  }
</script>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>

    <svelte:fragment slot="center">配達リスト</svelte:fragment>

    <svelte:fragment slot="right">
      <Menu bind:this={headerMapMenu} anchorCorner="BOTTOM_START">
        <List dense>
          {#if deliveryListByStatus.未.length >= 2}
            <Item
              on:SMUI:action={loadingProgress.wrapAsync(async () => {
                // TSPの計算量が多いせいか読込中のUI表示が間に合わないことがあるため、少しsleepを入れる
                await sleep(100);

                // 総移動距離が短い順（巡回セールスマン問題）でソート
                const currentPosition = $currentPositionStore;
                if (currentPosition) {
                  await deliveryListUtils.sortWithTSP(
                    shippingList,
                    $currentPositionStore,
                  );
                  // 並び替えたリストを画面に反映
                  shippingList = shippingList;
                  userContext.store();

                  if (
                    deliveryListUtils.existsPackageDesiredDateAndTime(
                      deliveryListByStatus.未,
                    )
                  ) {
                    // 未配達リスト内に配達指定日時または再配達の希望日時が設定された荷物が含まれている場合
                    toast.info(
                      $_("message.completeSortExistsDesiredDateTime"),
                      {
                        duration: 10000,
                      },
                    );
                  } else {
                    // 未配達リスト内に配達指定日時または再配達の希望日時が設定された荷物が含まれていない場合
                    toast.info($_("message.completeSort"));
                  }
                }
              })}
            >
              <Graphic class="material-icons">sort</Graphic>
              <Text>総移動距離が短い順に並び替え</Text>
            </Item>
          {/if}
          <Item
            on:SMUI:action={() => {
              if (!appContext.firstOpenRouteMap) {
                appContext.firstOpenRouteMap = true;
                routeMapMessageDialog.openDialog();
              }
              routeMapDialog.openDialog();
            }}
          >
            <Graphic class="material-icons">route</Graphic>
            <Text>配達経路地図を表示</Text>
          </Item>
        </List>
      </Menu>

      {#if lastPosition}
        <IconButton
          class="material-icons"
          size="button"
          style="font-size: 20px;"
          on:click={() => {
            headerMapMenu.setOpen(true);
          }}>map</IconButton
        >
      {/if}
      <IconButton
        class="material-icons"
        size="button"
        style="font-size: 20px;"
        on:click={loadingProgress.wrapAsync(onClickRefreshDeliveryList)}
        >refresh</IconButton
      >
    </svelte:fragment>
  </Header>

  {#if shippingList.length > 0}
    <div class="deliveryListTab">
      <TabBar tabs={deliveryListTabs} let:tab bind:active={activeTab}>
        <Tab {tab} ripple={false}>
          <Label>{tab.label}<sub>({tab.length.toLocaleString()})</sub></Label>
        </Tab>
      </TabBar>
    </div>
  {/if}

  <!-- プッシュ通知による更新通知エリア -->
  {#if hasUpdates && activeTab.length > 0}
    <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions -->
    <div class="hasUpdatesArea" on:click={scrollToUpdatedPackage}>
      <p>
        <span class="material-icons md-18">report</span>{updatesCountString}
      </p>
    </div>
  {/if}

  <!-- 送信失敗荷物の情報表示エリア -->
  {#if hasSyncFailurePackages}
    <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions -->
    <div
      class="hasSyncFailurePackagesArea"
      on:click={syncFailureListDialog.openDialog}
    >
      <p>
        <span class="material-icons md-18">report</span>
        送信待ちデータがあります。<u>タップして確認</u>
      </p>
    </div>
  {/if}

  <main in:fade>
    {#if shippingList.length > 0 && ((activeTab.label === "未配達" && (numberOfTodayPackages > 0 || numberOfOverduePackages > 0)) || (activeTab.label !== "配達完了" && (numberOfWaitingHandoverPackagesInUndeliveredList > 0 || numberOfWaitingHandoverPackagesInUndeliverableList > 0)))}
      <div class="noticeArea">
        {#if activeTab.label === "未配達" && (numberOfTodayPackages > 0 || numberOfOverduePackages > 0)}
          <div class="deadlineAndDesiredDate">
            <p>日時指定の荷物:</p>
            <p>
              <span class="material-icons today md-18">schedule</span><span
                >&nbsp;{numberOfTodayPackages.toLocaleString()}件<sub
                  >(当日指定)</sub
                ></span
              >
            </p>
            <p>
              <span class="material-icons overdue md-18"
                >running_with_errors</span
              >&nbsp;{numberOfOverduePackages.toLocaleString()}件<sub
                >(期限超過)</sub
              >
            </p>
          </div>
        {/if}
        {#if activeTab.label !== "配達完了" && (numberOfWaitingHandoverPackagesInUndeliveredList > 0 || numberOfWaitingHandoverPackagesInUndeliverableList > 0)}
          <div class="waitingHandoverArea">
            <p>引継ぎ待ちの荷物:</p>
            <p>
              <span
                >未{numberOfWaitingHandoverPackagesInUndeliveredList.toLocaleString()}件</span
              >
              <span style="margin-left: 8px;"
                >不可{numberOfWaitingHandoverPackagesInUndeliverableList.toLocaleString()}件</span
              >
              <span
                style="display: flex; align-items: center; margin-left: 8px;"
                >(状況更新<IconButton
                  class="material-icons"
                  size="button"
                  style="font-size: 20px; padding: 0; width: 20px; height: 20px; margin-left: 3px;"
                  on:click={loadingProgress.wrapAsync(
                    onClickRefreshDeliveryList,
                  )}>refresh</IconButton
                >)</span
              >
            </p>
          </div>
        {/if}
      </div>
    {/if}
    {#if pageInitialized}
      {#if shippingList.length > 0}
        {@const content =
          activeTab.label === "未配達"
            ? { list: deliveryListByStatus.未, type: "未配達の荷物" }
            : activeTab.label === "配達完了"
              ? { list: deliveryListByStatus.済, type: "配達が完了した荷物" }
              : {
                  list: deliveryListByStatus.不,
                  type: "配達不可の荷物",
                }}
        {#if content.list.length > 0}
          <section class="contentList">
            <ul>
              {#each content.list as listItem, index (listItem.trackingNumber)}
                <li
                  animate:flip={{ duration: 300 }}
                  draggable="true"
                  on:dragstart={(event) => handleDragStart(event, index)}
                  on:dragover|preventDefault={(event) =>
                    handleDragOver(event, index)}
                  on:dragleave|preventDefault={handleDragLeave}
                  on:drop|preventDefault={(event) => handleDrop(event, index)}
                >
                  <ListItem
                    {listItem}
                    index={appContext.fixNumberOnDeliveryList === false
                      ? index + 1
                      : listItem.numberOnDeliveryList}
                    bind:shippingList
                    on:changeDisplayingAddress={getShippingKnowledgeForSingle}
                  />
                </li>
              {/each}
            </ul>
          </section>
        {:else}
          <EmptyTabContent {deliveryListTabs}>
            <svelte:fragment slot="packageType">{content.type}</svelte:fragment>
          </EmptyTabContent>
        {/if}
      {:else}
        <EmptyListContent />
      {/if}
    {/if}
  </main>

  <Footer />
</div>

<div class="subContentsWrapper">
  <!-- ヘルプ表示 -->
  <svelte:component this={helpBase} {helpContents} {clickConfirm} />

  <!-- 経路地図ダイアログ -->
  <div class="routeMapDialog wideWidthMdcDialog">
    <ConfirmDialog bind:this={routeMapDialog} type={ConfirmDialogTypes.CLOSE}>
      <svelte:fragment slot="title"
        >配達経路地図&nbsp;<span style="font-size: small;"
          >(精度:番地レベル)</span
        ></svelte:fragment
      >
      <svelte:fragment slot="content">
        <RouteMap deliveryList={shippingList} />
      </svelte:fragment>
    </ConfirmDialog>
  </div>

  <!-- 経路地図注記メッセージダイアログ -->
  <ConfirmDialog
    bind:this={routeMapMessageDialog}
    type={ConfirmDialogTypes.CLOSE}
  >
    <svelte:fragment slot="title">配達経路地図について</svelte:fragment>
    <svelte:fragment slot="content">
      <p>おおよその位置を示しており100～200m程度の誤差があります。</p>
      <p style="margin-top: 3px;">
        正確なお届け先位置については、GODOOR等の住宅地図やGoogleMapを参照ください。
      </p>
    </svelte:fragment>
  </ConfirmDialog>

  <!-- 同期失敗リストダイアログ -->
  <SyncFailureListDialog
    bind:this={syncFailureListDialog}
    {reloadShippingList}
    bind:hasSyncFailurePackages
  />
</div>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  .noticeArea {
    background-color: #fff;
    border-bottom: solid 0.5px #d2d2d2;
    display: flex;
    flex-flow: column;
    gap: 2px 0;
    padding: 4px 0;
  }

  .deadlineAndDesiredDate {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 9px;

    p {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);

      .material-icons.today {
        color: #444;
      }
      .material-icons.overdue {
        color: var(--mdc-theme-error);
      }

      sub {
        font-size: 10px;
      }
    }
  }

  .waitingHandoverArea {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 9px;

    p {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);

      .material-icons {
        color: #444;
      }

      sub {
        font-size: 10px;
      }
    }
  }

  .deliveryListTab {
    background-color: #fff;
    border-bottom: solid 0.5px #d2d2d2;

    :global(.mdc-tab-scroller__scroll-content) {
      justify-content: center;
    }
    :global(.mdc-tab) {
      width: calc(100% / 3);
      height: 38px;
      padding: 0;
    }
    :global(.mdc-tab__text-label) {
      font-size: 13.5px;
      font-weight: 600;
      letter-spacing: 0;
    }
    :global(.mdc-tab__text-label sub) {
      padding-left: 2px;
    }
  }

  .hasUpdatesArea,
  .hasSyncFailurePackagesArea {
    display: flex;
    justify-content: center;
    padding: 6px 0;
    background-color: #ffdbdb;
    border-bottom: solid 0.5px #d2d2d2;

    p {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: rgba(196, 2, 2, 0.877);

      span {
        margin-right: 3px;
      }
    }
  }

  .contentList {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #eeeded;

    > ul {
      list-style: none;
      padding-left: 0;
      margin: 0 auto;
    }
  }

  .subContentsWrapper {
    .routeMapDialog {
      :global(.mdc-dialog__surface) {
        height: calc(100vh - 16px);
        max-height: none;
      }
      :global(.mdc-dialog__content) {
        padding: 0;
      }
    }
  }
</style>
